import { Component, OnInit } from '@angular/core';
import { infoLife } from '../../assets/info/info';
import { studies } from '../../assets/info/info';
import { infoSkills } from '../../assets/info/info';
import { infoJobs } from '../../assets/info/info';
import { fullName } from '../../assets/info/info';
import { currentJob } from '../../assets/info/info';
import { languajes } from '../../assets/info/info';
import { license } from '../../assets/info/info';
import { spotify } from '../../assets/info/info';
declare var jQuery:any;
declare var $:any;
@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {

  constructor() { }
  
  numberOfClicks = 0;
  infoLife = infoLife;
  studies = studies;
  infoSkills = infoSkills;
  infoJobs = infoJobs;
  name = fullName;
  job = currentJob;
  languajes = languajes;
  license = license;
  spotify = spotify;
  

  ngOnInit() {

    console.log(spotify);
    // INICIALIZAR MATERIALIZE
    $('.modal').modal();
    $('.collapsible').collapsible();

    // FUNCION MATERIALIZE
    jQuery(window).ready(function() {
      jQuery('.skillbar').each(function(){
        jQuery(this).find('.skillbar-bar').animate({
          width:jQuery(this).attr('data-percent')
        },0);
      });
      jQuery( spotify ).each(function() {
        console.log($( this ).addClass( "foo" ));
        
        jQuery('#'+this.id).attr("src",this.link);
      });

    });

  }

  countClick(){
    this.numberOfClicks++;
    if(this.numberOfClicks == 15){
      alert("Yeh");
      this.numberOfClicks = 0;
    }
  }

}
