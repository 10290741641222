
let fullName = "Faustino Contreras"

let currentJob = "< Developer />";

let infoLife = [
    {
      "startDate" : "2020",
      "type" : "Trabajo",
      "description" : "Actualmente Trabajando en Grupotel de tecnico superior en desarrollo de aplicaciones.",
    },
    {
      "startDate" : "2019",
      "type" : "Trabajo",
      "description" : "Ca S'amitger de tecnico superior en desarrollo de aplicaciones.",
    },
    {
      "startDate" : "2018",
      "type" : "Trabajo",
      "description" : "Ca S'amitger de tecnico superior en desarrollo de aplicaciones.",
    },
    {
      "startDate" : "2018",
      "type" : "Estudio",
      "description" : "Segundo de Grado Superior de Aplicaciones Multiplataforma IES PAU Casesnoves",
    },
    {
      "startDate" : "2017",
      "type" : "Trabajo",
      "description" : "Ca S'amitger de tecnico superior en desarrollo de aplicaciones.",
    },
    {
      "startDate" : "2017",
      "type" : "Practicas",
      "description" : "Practicas de Grado Superior de Aplicaciones WEB en Kamalyon.",
    },
    {
      "startDate" : "2017",
      "type" : "Estudio",
      "description" : "Segundo de Grado Superior de Aplicaciones WEB IES PAU Casesnoves",
    },
    {
      "startDate" : "2017",
      "type" : "Trabajo",
      "description" : "Desarrollador Junior Mind2Cloud",
    },
    {
      "startDate" : "2016",
      "type" : "Trabajo",
      "description" : "Desarrollador Junior Mind2Cloud",
    },
    {
      "startDate" : "2016",
      "type" : "Estudio",
      "description" : "Primero de Grado Superior de Aplicaciones WEB IES PAU Casesnoves",
    },
  ];

let studies = [
  {
    "icon" : "devices_other",
    "name" : "Desarrollo de aplicaciones multiplataforma.",
    "description" : "IES Pau Casesnoves - Tecnico superior en desarrollo de aplicaciones multiplataforma.",
    "other" : ""
  },
  {
    "icon" : "devices",
    "name" : "Desarrollo de aplicaciones WEB.",
    "description" : "IES Pau Casesnoves - Tecnico superior en desarrollo de aplicaciones WEB.",
    "other" : ""
  },
  {
    "icon" : "desktop_windows",
    "name" : "Sistemas Microinformaticos y Redes.",
    "description" : "IES Pau Casesnoves - Tecnico Sistemas Microinformaticos y Redes.",
    "other" : "Finalizando el curso con practicas en el extranjero con le proyecto \"Erasmus+\" Se realizaron en Budapest - Hungria en la empresa Laptopszerviz"
  },
]

  
let infoSkills = [
  {
      "percent":"95%",
      "name":"PHP",
      "colorA": "#777bb3",
      "colorB": "#888dce"
  },
  {
      "percent":"75%",
      "name":"SQL",
      "colorA": "#DD8A00",
      "colorB": "#f29a0e"
  },
  {
      "percent":"40%",
      "name":"Java",
      "colorA": "#d64d37",
      "colorB": "#ea563f"
  },
  {
      "percent":"75%",
      "name":"JavaScript",
      "colorA": "#EFD81D",
      "colorB": "#ffe20a"
  },
  {
      "percent":"80%",
      "name":"AJAX",
      "colorA": "#2F84C5",
      "colorB": "#3497e2"
  },
  {
      "percent":"90%",
      "name":"CSS",
      "colorA": "#78bdab",
      "colorB": "#88d8c3"
  },
  {
      "percent":"50%",
      "name":"C#",
      "colorA": "#642076",
      "colorB": "#8e2fa8"
  },
  {
    "percent":"95%",
    "name":"XML",
    "colorA": "#7F9C1E",
    "colorB": "#97b724"
  },
  {
      "percent":"75%",
      "name":"jQuery",
      "colorA": "#162b43",
      "colorB": "#254a75"
  },
  {
      "percent":"65%",
      "name":"Angular",
      "colorA": "#d61a15",
      "colorB": "#ef1d1a"
  },
  {
      "percent":"40%",
      "name":"Node JS",
      "colorA": "#8bbf3d",
      "colorB": "#99d145"
  },
  {
      "percent":"60%",
      "name":"Python",
      "colorA": "#EFCC3E",
      "colorB": "#ffdc54"
  },
  {
      "percent":"65%",
      "name":"Laravel",
      "colorA": "#4f4f4f",
      "colorB": "#6b6b6b"
  },
  {
    "percent":"95%",
    "name":"HTML",
    "colorA": "#dd4b25",
    "colorB": "#e96228"
  },
  {
    "percent":"70%",
    "name":"Bootstrap",
    "colorA": "#5b3e84",
    "colorB": "#6B3AB4"
  },
  {
    "percent":"70%",
    "name":"Materialize",
    "colorA": "#e46c73",
    "colorB": "#ed9da7"
  },
]

let infoJobs = [
  {
    "title" : "Artes Marciales",
    "description" : "Los deportes que suelo practicar principalmente suelen ser artes marciales o de contacto el Taekwondo, K1, KikBoxin y Thai Boxing, aunque eventualmente también hago otros.",
    "urlImage" : "../../assets/img/ma.jpg"
  },
  {
    "title" : "Videojuegos",
    "description" : "Desde pequeño los videojuegos es una de las cosas que más me apasionan, sobretodo aquellos que te sumergen en la historia principal del mismo y las bandas sonoras te hacen volar por esos mundos. Mi saga de juegos predilecta es Final Fantasy.",
    "urlImage" : "../../assets/img/videogame.jpg"
  },
  {
    "title" : "Socializar con amigos y compañeros",
    "description" : "Una de las cosas que me gusta mas en mi tiempo libre es quedar con los amigos y compañeros e ir a tomar algo, sea duespues del trabajo o el fin de semana.",
    "urlImage" : "../../assets/img/beer.png"
  },
  {
    "title" : "Juegos de mesa",
    "description" : 'Los juegos de mesa son otra des mis grandes aficiones ya que "junta" dos de mis grandes hobbies que son los compañeros y amigos junto con los juegos.',
    "urlImage" : "../../assets/img/ajedrez.jpg"
  },
]

let languajes = [
  {
    "lang" : "Español",
    "lvl" : "Nativo"
  },
  {
    "lang" : "Catalán",
    "lvl" : "Nativo"
  },
  {
    "lang" : "Ingles",
    "lvl" : "Tecnico"
  },
]

let license = [
  {
    "name" : "Carnet de conducir B1"    
  },
  {
    "name" : "Certificado de (S.A.P.) Salvamento Acuático Profesional , S.U.L"    
  },
  {
    "name" : "Certificado de formación laboral y experiencia laboral."    
  },
  {
    "name" : "Certificado riesgos laborables."    
  },
  {
    "name" : "Carnet manipulación fito-sanitarios."    
  },
]

let spotify = [
  // {
  //   "id" : "spo1",
  //   "title" : "Distant Worlds Collection",
  //   "link" : "https://open.spotify.com/embed/playlist/1RpoFbF2ZzuLxhhOc3djCe"
  // },
  {
    "id" : "spo2",
    "title" : "Rock Classics",
    "link" : "https://open.spotify.com/embed/playlist/37i9dQZF1DWXRqgorJj26U"
  },
  {
    "id" : "spo3",
    "title" : "All Out 80's",
    "link" : "https://open.spotify.com/embed/playlist/6yZGOpJCmGy7k2LSe4DiiO"
  },
  {
    "id" : "spo4",
    "title" : "Nobuo Uematsu",
    "link" : "https://open.spotify.com/embed/artist/3V79CTgRnsDdJSTqKitROv"
  },
]

export {
    infoLife,
    studies,
    infoSkills,
    infoJobs,
    fullName,
    currentJob,
    languajes,
    license,
    spotify
};